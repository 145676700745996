// import 'zone.js'; // for angular subapp
import { registerMicroApps, runAfterFirstMounted, start, setDefaultMountApp } from 'qiankun';
import actions from '../action';
const loader = () => {
    // console.log(loading);
};
export const appsData = [
    {
        name: 'powerTrading',
        entry: process.env.VUE_APP_POWER_TRADING,
        container: '#subapp-viewport',
        activeRule: '/powerTrading',
        loader,
        props: {
            name: '电力交易1.0'
        },
        sandbox: true
    },
    {
        name: 'enterpriseSearch',
        entry: process.env.VUE_APP_ENTER_SEARCH,
        container: '#subapp-viewport',
        activeRule: '/enterpriseSearch',
        loader,
        sandbox: true
    },
    {
        name: 'energyTesting',
        entry: process.env.VUE_APP_ENTER_TESTING,
        container: '#subapp-viewport',
        activeRule: '/energyTesting',
        loader,
        sandbox: true,
        props: {
            actions,
            routerBase: '/energyTesting', // 子应用的路由前缀(router的base)
            routerList: [] // 子应用的路由列表
        }
    },
    {
        name: 'transactionCalendar',
        entry: process.env.VUE_APP_TRANSACTION_CALENDAR,
        container: '#subapp-viewport',
        loader,
        activeRule: '/transactionCalendar',
        sandbox: true,
        props: {
            routerBase: '/transactionCalendar', // 子应用的路由前缀(router的base)
            routerList: [] // 子应用的路由列表
        }
    },
    {
        name: 'spotElectricityPriceForecast',
        entry: process.env.VUE_APP_SPOT_ELECTRICITY_PRICE_FORECAST,
        container: '#subapp-viewport',
        activeRule: '/spotElectricityPriceForecast',
        sandbox: true,
        loader,
        props: {
            routerBase: '/spotElectricityPriceForecast', // 子应用的路由前缀(router的base)
            routerList: [] // 子应用的路由列表
        }
    },
    {
        name: 'holdKanban',
        entry: process.env.VUE_APP_HOLD_KANBAN,
        container: '#subapp-viewport',
        activeRule: '/holdKanban',
        sandbox: true,
        loader,
        props: {}
    },
    {
        name: 'holdKanban3',
        entry: process.env.VUE_APP_HOLD_KANBAN3,
        container: '#subapp-viewport',
        activeRule: '/holdKanban3',
        sandbox: true,
        loader,
        props: {}
    },
    {
        name: 'estimateDaypartingQuotation',
        entry: process.env.VUE_APP_ESTIMATE_DAYPARTING_QUOTATION,
        container: '#subapp-viewport',
        activeRule: '/estimateDaypartingQuotation',
        loader,
        sandbox: true
    },
    {
        name: 'planReview',
        entry: process.env.VUE_APP_PLAN_REVIEW,
        container: '#subapp-viewport',
        activeRule: '/planReview',
        loader,
        sandbox: true
    },
    {
        name: 'planReview3',
        entry: process.env.VUE_APP_PLAN_REVIEW3,
        container: '#subapp-viewport',
        activeRule: '/planReview3',
        loader,
        sandbox: true
    },
    {
        name: 'planReviewE',
        entry: process.env.VUE_APP_PLAN_REVIEWE,
        container: '#subapp-viewport',
        activeRule: '/planReviewE',
        loader,
        sandbox: true
    },
    {
        name: 'customerManagement',
        entry: process.env.VUE_APP_CUSTOMER_MANAGEMENT,
        container: '#subapp-viewport',
        activeRule: '/customerManagement',
        loader,
        sandbox: true
    },
    {
        name: 'settlementManagement',
        entry: process.env.VUE_APP_SETTLEMENT_MANAGEMENT,
        container: '#subapp-viewport',
        activeRule: '/settlementManagement',
        loader,
        sandbox: true
    },
    {
        name: 'HistoricalElectricityConsumption',
        entry: process.env.VUE_APP_HISTORICAL_ELECTRICITY_CONSUMPTION,
        container: '#subapp-viewport',
        activeRule: '/HistoricalElectricityConsumption',
        loader,
        sandbox: true
    },
    {
        name: 'disclosureData',
        entry: process.env.VUE_APP_DISCLOSURE_DATA,
        container: '#subapp-viewport',
        activeRule: '/disclosureData',
        loader,
        sandbox: true
    },
    {
        name: 'operationManagement',
        entry: process.env.VUE_APP_OPERATION_MANAGEMENT_DATA,
        container: '#subapp-viewport',
        activeRule: '/operationManagement',
        loader,
        sandbox: true
    },
    {
        name: 'application',
        entry: process.env.VUE_APP_APPLICATION,
        container: '#subapp-viewport',
        activeRule: '/application',
        loader,
        sandbox: true,
        props: {
            actions,
        }
    },
    {
        name: 'backstageManagement',
        entry: process.env.VUE_APP_BACKSTAGE_MANAGEMENT,
        container: '#subapp-viewport',
        activeRule: '/backstageManagement',
        loader,
        sandbox: true
    },
    {
        name: 'retailMarket',
        entry: process.env.VUE_APP_RETAILMARKET,
        container: '#subapp-viewport',
        activeRule: '/retailMarket',
        loader,
        sandbox: true
    },
    {
        name: 'cgd',
        entry: process.env.VUE_APP_CABLE_GAS,
        container: '#subapp-viewport',
        activeRule: '/cgd',
        loader,
        sandbox: true
    },
    {
        name: 'weatherForecast',
        entry: process.env.VUE_APP_WEATHERFORECAST,
        container: '#subapp-viewport',
        activeRule: '/weatherForecast',
        loader,
        sandbox: true
    },
    {
        name: 'noticeRelease',
        entry: process.env.VUE_APP_NOTICE_RELEASE,
        container: '#subapp-viewport',
        activeRule: '/noticeRelease',
        loader,
        sandbox: true
    },
    {
        name: 'energyStorage',
        entry: process.env.VUE_APP_ENERGYSTORAGE,
        container: '#subapp-viewport',
        activeRule: '/energyStorage',
        loader,
        sandbox: true
    },
    {
        name: 'energyStorage2',
        entry: process.env.VUE_APP_ENERGYSTORAGE2,
        container: '#subapp-viewport',
        activeRule: '/energyStorage2',
        loader,
        sandbox: true
    },
    {
        name: 'energyMaintenance',
        entry: process.env.VUE_APP_ENERGYMAINTENANCE,
        container: '#subapp-viewport',
        activeRule: '/energyMaintenance',
        loader,
        sandbox: true
    },
    {
        name: 'workflow',
        entry: process.env.VUE_APP_WORKFLOWAPP,
        container: '#subapp-viewport',
        activeRule: '/workflow',
        loader,
        props: {
            name: '储能运维平台工作流',
            routerBase: '/workflow',
            actions
        },
        sandbox: true
    },
    {
        name: 'projectManagement',
        entry: process.env.VUE_APP_PROJECTMANAGEMENT,
        container: '#subapp-viewport',
        activeRule: '/projectManagement'
    },
    {
        name: 'projectManagementInside',
        entry: process.env.VUE_APP_PROJECTMANAGEMENTINSIDE,
        container: '#subapp-viewport',
        activeRule: '/projectManagementInside'
    },
    {
        name: 'intelligentTerminal',
        entry: process.env.VUE_APP_INTELLIGENTTERMINAL,
        container: '#subapp-viewport',
        activeRule: '/intelligentTerminal'
    },
    {
        name: 'powerGrid',
        entry: process.env.VUE_APP_POWERGRID,
        container: '#subapp-viewport',
        activeRule: '/powerGrid'
    },
    {
        name: 'virtualPowerPlant',
        entry: process.env.VUE_APP_VIRTUALPOWERPLANT,
        container: '#subapp-viewport',
        activeRule: '/virtualPowerPlant',
        sandbox: {
            strictStyleIsolation:true,// 开启样式隔离
        },
        props: {
            actions,
        }
    },
    {
        name: 'tenderingAndBidding',
        entry: process.env.VUE_APP_TENDERINGANDBIDDING,
        container: '#subapp-viewport',
        activeRule: '/tenderingAndBidding',
    },
    /* 3,0*/
    {
        name: 'enterpriseSearch3',
        entry: process.env.VUE_APP_ENTER_SEARCH3,
        container: '#subapp-viewport',
        activeRule: '/enterpriseSearch3',
        loader,
        sandbox: true
    },
    {
        name: 'customerManagement3',
        entry: process.env.VUE_APP_CUSTOMER_MANAGEMENT3,
        container: '#subapp-viewport',
        activeRule: '/customerManagement3',
        loader,
        sandbox: true
    },
    {
        name: 'spotElectricityPriceForecast3',
        entry: process.env.VUE_APP_SPOT_ELECTRICITY_PRICE_FORECAST3,
        container: '#subapp-viewport',
        activeRule: '/spotElectricityPriceForecast3',
        sandbox: true,
        loader,
        props: {
            routerBase: '/spotElectricityPriceForecast3', // 子应用的路由前缀(router的base)
            routerList: [] // 子应用的路由列表
        }
    },
    {
        name: 'backstageManagement3',
        entry: process.env.VUE_APP_BACKSTAGE_MANAGEMENT3,
        container: '#subapp-viewport',
        activeRule: '/backstageManagement3',
        loader,
        sandbox: true
    },
];
// 动态注册子应用的函数
export function dynamicRegisterApps(app) {
    const apps = app;
    // 可以在这里根据条件过滤出需要注册的子应用
    // 比如根据用户角色、权限、配置等条件
    registerMicroApps(apps, {
        beforeLoad: [
            /* ... */
        ],
        beforeMount: [
            /* ... */
        ],
        afterMount: [
            /* ... */
        ],
        afterUnmount: [
            /* ... */
        ]
    });
}

// router.beforeEach(async (to, from, next) => {
//     let pathname = location.pathname;
//         let url = '/' + pathname.split('/')[1];
//         let arr = appsData.filter((item) => {
//             return item.activeRule === url;
//         });
//         dynamicRegisterApps(arr);
//         next();
//
// });


// 启动 qiankun
start({
    prefetch: true
    // 其他配置...
});
actions.onGlobalStateChange((state, preState) => {
    console.log(state,preState)
})
