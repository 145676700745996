<template>
  <div>
    <div class="page-main">
      <Nav :is-menu="true" :isView="true" :userInfo="userInfo" :shortcutKeyList="shortcutKeyList" :mode="zhuti.mode"
           :menuList="menuList" :defaultMenuGroup="defaultMenuGroup"
           :defaultMenuId="defaultMenuId" ref="navRef"></Nav>
      <!-- 面包屑导航 -->
      <div class="pt-[10px]  p-l-20 p-r-20"
           v-if="zhuti.mode==='horizontal'&& breadcrumbData">
        <div class='flex items-center flex-wrap '>
          <span>
            <span class="text-[12px]">
              <span class="text-[12px] span-primary"
              >
              我是一级菜单
              </span>
            </span>
             <span class="text-[12px]">
              <span class="text-[12px]"> / </span>
              <span class="text-[12px] span-text"
              >
             我是当前菜单
              </span>
            </span>
          </span>
        </div>
      </div>
      <div class='layout flex '
           :style="{height: zhuti.mode === 'horizontal'  ? 'calc(100% - 116px)'  : 'calc(100% - 67px)'}"
      >
        <div
            class='layout-sidebar bg-menu flex flex-col layoutSidebar_style transition-width duration-200  menu-shadow '
            v-if="zhuti.mode==='vertical'"
        >
          <div class='layout-sidebar-menubar overflow-hidden h-full'>
            <div class="p20 flex menu-line">
              <el-input
                  v-model="menuValue"
                  @input="menuValueChange"
                  placeholder="检索功能"
                  :prefix-icon="SearchIcon"
                  style="width: 138px"
                  class="searchIcon"
                  v-if="!isCollapse"
              />
              <el-tooltip :content="isCollapse?'展开':'隐藏'" placement="right" effect="light"
                          popper-class="tooltips">
                <el-button style="width: 32px;padding:12px 0;" @click="isCollapseFun" class="ml-1.5 collapseBtn"
                           :icon="!isCollapse?DArrowLeftIcon:DArrowRightIcon"/>
              </el-tooltip>
            </div>
            <el-scrollbar wrap-class='scrollbar-wrapper' style="height:calc(100% - 95px)">
              <sliderMenu :menuList="menuList" :collapse="isCollapse"
                          :defaultMenuGroup="defaultMenuGroup"
                          :menuIndex="menuIndex"
                          :defaultActive="defaultMenuId"
                          :mode="zhuti.mode"></sliderMenu>
            </el-scrollbar>
          </div>
        </div>

        <div class='layout-main'
             :class="zhuti.mode==='horizontal'?'pt-10':'pt-20'"
             :style="{width: zhuti.mode==='vertical' && menuList.length?!isCollapse?'calc(100% - 216px)':'calc(100% - 78px)':'100%'}"
        >
          <div class=" border-card" style="height: 500px;width: 800px" ref="echartsRef">

          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import "@/utils/registerMicroAppsConfig"
import {getUserInfoByToken} from "@/api/UserInfo";
import {mapMutations, mapState} from "vuex";
import {Search, DArrowLeft, DArrowRight} from '@element-plus/icons-vue';
import {getApi, getAppInfo, getAppQuickFuncListApi, getDicApi, getMenuPrv, getOrgInfoByUser, noticeCount} from "@/api";
import sliderMenu from '@/components/sliderMenu.vue'
import {appsData, dynamicRegisterApps} from "@/utils/registerMicroAppsConfig";
import Nav from "@/components/Nav.vue"
import {camelCase, handleThemeStyle, updateTopic} from "@/utils/common";
import * as echarts from 'echarts';

export default {

  name: "qiankunMenu",
  components: {
    Nav,
    sliderMenu,
    Search
  },
  computed: {
    // 定义 SearchIcon 变量为 Search 图标组件
    SearchIcon() {
      return Search;
    }
    , DArrowLeftIcon() {
      return DArrowLeft;
    }, DArrowRightIcon() {
      return DArrowRight;
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.getTopicInfo()
    //导航显示方式字典类型：
 this.initChart()
  },
  data() {
    return {
      optionsArray: [],
      navigationType: null,
      id: null,
      isShowMack: false,
      intervalId: null,
      menuIndex: 0,
      userInfo: {},
      shortcutKeyList: [],  // 快捷功能列表
      isLoginState: false, //  是否登录
      isMain: true, //  是否在主应用
      isView: false, //  是否是预览页
      pathname: "",
      breadcrumbData: '', // 面包屑导航
      isCollapse: true,
      menuValue: '',
      zhuti: {
        mode: ''
      },
      defaultMenuId: "",
      defaultMenuGroup: ['0-1'],
      userAvatar: '',
      logoPhoto: "",
      chooseTenant: "", // 选择的租户 默认第一个
      chooseTenantName: "", // 选择的租户 默认第一个
      tenantList: [],
      appList: [],
      menuList: [
        {
          "id": "9db2c4e8364d488497cec62f79a99503",
          "parentId": "0",
          "name": "菜单1",
          "icon": null,
          "path": "",
          "priority": 157,
          "valid": "0",
          "showFlag": "1",
          "parentPath": "客户管理",
          "level": 0
        },
        {
          "id": "4b89652559cb4518a8d55d4ebbe20199",
          "parentId": "0",
          "name": "菜单2",
          "icon": null,
          "path": null,
          "priority": 292,
          "valid": "0",
          "showFlag": "1",
          "children": [{
            "id": "f9ed21db37124745baf3e77abef95c32",
            "parentId": "4b89652559cb4518a8d55d4ebbe20199",
            "name": "菜单2-1",
            "icon": null,
            "path": "",
            "priority": 2,
            "valid": "0",
            "showFlag": "1",
            "parentPath": "菜单2-1-3",
            "level": 1
          }, {
            "id": "d0131ec884bf4dd69946b8e491087c2c",
            "parentId": "4b89652559cb4518a8d55d4ebbe20199",
            "name": "菜单2-1-3",
            "icon": null,
            "path": "",
            "priority": 3,
            "valid": "0",
            "showFlag": "0",
            "parentPath": "菜单2-1-3",
            "level": 1
          }, {
            "id": "01df588ced5d492ca513f16a9fcefb53",
            "parentId": "4b89652559cb4518a8d55d4ebbe20199",
            "name": "菜单2-1-3",
            "icon": null,
            "path": "",
            "priority": 4,
            "valid": "0",
            "showFlag": "1",
            "parentPath": "菜单2-1-3",
            "level": 1
          }],
          "parentPath": "菜单2-1-3",
          "level": 0
        },],      // 检索出来的菜单功能
      menuListAll: [],   // 全部菜单功能
      isHomeState: true
    }
  },
  methods: {
    initChart() {
      let myChart = echarts.init(this.$refs.echartsRef).dispose();
      myChart = echarts.init(this.$refs.echartsRef);
      let option = {
        color: JSON.parse(sessionStorage.getItem('echartsColor')),
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: 'Email',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: 'Union Ads',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            data: [220, 182, 191, 234, 290, 330, 310]
          },
          {
            name: 'Video Ads',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            data: [150, 232, 201, 154, 190, 330, 410]
          },
          {
            name: 'Direct',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            data: [320, 332, 301, 334, 390, 330, 320]
          },
          {
            name: 'Search Engine',
            type: 'line',
            stack: 'Total',
            label: {
              show: true,
              position: 'top'
            },
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            data: [820, 932, 901, 934, 1290, 1330, 1320]
          }
        ]

      };
      myChart.setOption(option);
    },
    ...mapMutations(['setEditableTabs', 'SET_APPINFO']),
    //  获取主题详细信息
    getTopicInfo() {
      getDicApi('NAVIGATION_VIEW_TYPE').then((res) => {
        this.navigationType = res.data
        getApi("/V2.0/app/topic/query", {topicId: this.id}).then(res => {
          if (res.code == 200) {
            let topic = res.data || {}
            let m = this.navigationType.filter(item => item.key === topic.navigationViewType)[0].value // 1横向  2纵向
            // 自定义现在的主题样式
            this.zhuti = {
              mode: m === '横向' ? 'horizontal' : 'vertical', //vertical , horizontal
            }
            topic.navigationViewType = m === '横向' ? '1' : '2'
            updateTopic(topic)
            this.$forceUpdate()
          }
        })
      })

    },
    extractTheme(colorList) {
      const theme = {};
      colorList.forEach(item => {
        let camelCaseKey = camelCase(item.key);
        if (item.key && item[camelCaseKey]) {
          theme[item.key] = item[camelCaseKey];
        }
      });
      return theme;
    },
    async isSuccess(type = '') {
      this.tenantList = []
      // 获取用户信息
      const getInfoResult = await this.getInfo();
      if (getInfoResult.code === 200) {
        await this.getOrgInfoByUserApi()
      }
    }
    ,
    /* 调用获取租户列表的list */
    getOrgInfoByUserApi() {
      getOrgInfoByUser().then(res => {
        /*
      *  获取组织信息 -- 如果组织不存在
      *  补充资料从组织下手
      * */
        if (!res.data.length) {
          this.$router.push({path: "/additionInfo", query: {active: 2}})
          return
        }
        if (res.code == 200 && res.data.length) {
          let tenantList = res.data || []
          this.tenantList = tenantList

          this.chooseTenant = sessionStorage.getItem('chooseTenant') ? sessionStorage.getItem('chooseTenant') : this.tenantList[0].orgCode
          this.chooseTenantName = sessionStorage.getItem('chooseTenantName') ? sessionStorage.getItem('chooseTenantName') : this.tenantList[0].orgName
          // document.title = this.chooseTenantName  // 设置页面标题
          // this.senName = this.tenantList[0].senName
          // let orgFullName = localStorage.getItem('orgFullName') ? localStorage.getItem('orgFullName') : this.tenantList[0].orgFullName
          // localStorage.setItem('orgFullName', orgFullName)
          // localStorage.setItem('chooseTenant', this.chooseTenant)
          // localStorage.setItem('chooseTenantName', this.chooseTenantName)
          // let photo = this.tenantList[0].photoTemp ? this.tenantList[0].photoTemp : null
          // this.logoPhoto = localStorage.getItem('logoPhoto') ? localStorage.getItem('logoPhoto') : photo
          // 获取应用列表信息
          this.getAppInfoApi()
          // getlogoinfo().then(res=>{
          //   this.imgUrl=res.data.url
          // })

        } else {
          //  企业未认证
          this.tenantList = []
          this.appList = []
        }

      }).catch(err => {
        // 401 不直接调用接口退出   要清除缓存
        this.handleOptins('401')
      })
    }
    ,
// 获取应用列表信息
    getAppInfoApi() {
      getAppInfo(this.chooseTenant).then(res => {
        this.appList = res.data || []
        /*
             *  获取应用信息，如果应用不存在直接添加应用
             * */
        // if (!this.appList.length) {
        //   this.$router.push({path: "/additionInfo", query: {active: 3}})
        //   return
        // }
        /* 新增一个逻辑，如果列表只有一个直接跳进去  */
        // if (this.appList.length === 1) {
        //   this.$nextTick(() => {
        //     this.$refs.comAbourRef.chooseApp(this.appList[0])
        //   })
        // }
      })
    }
    ,
    getInfoListMenu(arr, pathname, parent = null) {
      for (let i = 0; i < arr.length; i++) {
        const path = arr[i].path
        const node = arr[i]
        if (pathname === path) {
          node.parentName = parent.menuName
          return node
        }
        if (typeof node === 'object' && node.children && node.children.length > 0) {
          const foundItem = this.getInfoListMenu(arr[i].children, pathname, arr[i]);
          if (foundItem) {
            return foundItem;
          }
        }
      }
      return null;
    }
    ,
    getInfo() {
      // 根据 根据token获取用户名
      return getUserInfoByToken().then(r => {
        // if (r.code === 200) {
        //   return getUserInfo({username: r.data.userName}).then(res => {
        //     // let imageUrl = res.data.sysUser.photoTemp ? res.data.sysUser.photoTemp : '';
        //     // this.SET_HEADERIMG(imageUrl);
        //     return {code: 200};
        //   });
        // } else {
        //   return {code: r.code};
        // }
      }).catch((err) => {
        // 401 不直接调用接口退出   要清除缓存
        this.handleOptins('401')
        return {code: 500}; // 其他错误
      });
    }
    ,
// getInfo(){
//   //localStorage.getItem('name')
//   getUserInfo({username:localStorage.getItem('name')}).then(res=>{
//     this.userAvatar = res.data.sysUser.photo ?  process.env.VUE_APP_URL_IMG  + res.data.sysUser.photo : ''
//   })
// },
    goHome(text) {

      this.$emit('goHome', text)
    }
    ,
    handleOptins(text) {
      this.$emit('handleOptins', text)
    }
    ,
// 展开隐藏菜单
    isCollapseFun() {
      this.isCollapse = !this.isCollapse
      document.querySelectorAll('.tooltips').forEach(element => {
        element.style.display = 'none'
      });

    }
    ,
    navigatorTo(row, id, groupId) {
      let to = row.path
      this.$router.push(to)
      let url = '/' + to.split('/')[1];
      let arr = appsData.filter((item) => {
        return item.activeRule === url;
      });
      dynamicRegisterApps(arr);
    }
    ,
  },
}
</script>
<style lang="less">
#subapp-viewport {
  height: 100%;
  width: 100%;

  & > div {
    height: 100%;
    width: 100%;
  }
}
</style>
<style lang="less" scoped>
@import "../assets/css/common.less";

::v-deep(.searchIcon) {
  .el-icon {
    color: var(--el-menu-search-icon-color) !important;
  }
}

.page-main {
  height: 100vh;
  width: 100vw;
  background: var(--page-bg);

  .menu-line {
    border-bottom: 1px solid var(--el-menu-line-color)
  }

  .layout {
    ::v-deep(.collapseBtn) {
      color: var(--el-menu-search-arrow-icon-color);
    }

    ::v-deep(.collapseBtn:hover) {
      background: var(--el-menu-search-arrow-hover-bg-color) !important;
      color: var(--el-menu-search-arrow-icon-hover-color) !important;
      border-color: transparent !important;

      .el-icon {
        color: var(--el-menu-search-arrow-icon-hover-color) !important;
      }
    }

    ::v-deep(.collapseBtn.el-button, .collapseBtn.el-button ) {
      color: var(--el-menu-search-arrow-icon-color);
      border-color: transparent !important;
      background-color: var(--el-menu-search-arrow-bg-color);
      outline: 0;
      margin-left: 0.375rem;
    }

    .searchIcon {
      ::v-deep(.el-input__prefix) {
        color: var(--el-menu-search-arrow-icon-color);
      }

      ::v-deep(input::-webkit-input-placeholder) {
        -webkit-text-fill-color: var(--el-menu-search-place-color);
      }

      ::v-deep(.el-input__wrapper) {
        .el-input__inner {
          color: var(--el-menu-search-active-text-color) !important;
          border: none;
          background: none !important;
          height: 32px;
        }

        background-color: var(--el-menu-search-active-bg-color);
      }

      ::v-deep(.el-input__wrapper.is-focus) {
        .el-input__inner {
          color: var(--el-menu-search-active-border-color) !important;
        }

        background-color: var(--el-menu-search-active-bg-color);
        border: 1px solid var(--el-menu-search-active-border-color);
      }

      ::v-deep(.el-input__wrapper) {
        background-color: var(--el-menu-search-bg-color);
        box-shadow: 0 0 0 1px var(--el-menu-search-border-color, var(--el-menu-search-border-color)) inset;
      }
    }
  }

  .danwei {
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    text-align: left;
    font-style: normal;
    text-transform: none;

    .line {
      width: 1px;
      height: 30px;
      background: @border-color;
    }
  }

  .shortcut {
    display: inline-block;
    height: 20px;
    margin-right: 20px;
  }

  .p_box {

    .p1 {
      color: @text-color;
      font-size: 14px;
    }

    .p2 {
      color: @text-color;
      font-size: 14px;
      font-family: Microsoft YaHei, Microsoft YaHei;
    }

    .p3 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 20px;
      color: #333333;
    }

    .p4 {
      font-family: Microsoft YaHei, Microsoft YaHei, serif;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
    }


  }

  .tips {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #FF0000;
    border-radius: 50%;
  }
}

.bg-white {
  background: #fff;
}

.layout-main {
  background: var(--page-bg);
  padding: 0 20px;
  padding-bottom: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-10 {
  padding-top: 10px;
}

.menu-shadow {
  //box-shadow: 0px 0px 6px 1px rgba(3, 75, 143, 0.1608);
  border-right: 1px solid var(--menu-right)
}
</style>

<style>
.el-menu-item.is-active {
  color: var(--el-menu-active-color) !important;
}

.classTestwyc {
  display: inline-block;
  padding: 10px 20px;
  width: 88px;
  height: 76px;
}

.classTestwyc .el-dropdown__list {
  height: calc(100%);
}

.classTestwyc .el-dropdown-menu {
  height: 100%;
  width: 100%;
  margin: 0;
}

.classTestwyc .el-dropdown-menu .el-dropdown-menu__item {
  /* height: 100%; */
  width: 100%;
  line-height: 22px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
  margin: 0px;
  display: block;
  padding-bottom: 3px;
  padding-top: 3px;
}

.span-primary {
  color: var(--el-color-primary);
}

.span-text {
  color: #8C8C8C;
}


</style>
